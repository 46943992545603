*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Courier New', Courier, monospace;

}
.main {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #282c34;
}
.App {
	background-color: #282c34;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
h1{
	font-weight: 100;
	font-size: 60px;
	color: aliceblue;
	padding-bottom: 40px;
}
p{
	color: white;
}

.App > div {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: fit-content;
	min-height: 200px;
}
textarea {
	max-width: 1200px;
	width: 100%;
	margin: 10px 0;
	max-height: 200px;
}
.hash {
	width: 500px;
	max-height: 70px;
	max-width: 1200px;
}
.buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 40px;
	margin: 40px 0;
}
.buttons > input {
	padding: 10px 30px;
	cursor: pointer;
	width: 48%;
}
input[type="number"] {
	padding: 5px 20px 5px 5px;
	width: 120px;
  margin: 10px 0;
}
input::placeholder {
  color: black;
}
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}
 */
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */


.key { 
	margin: 20px 0	;
}
.key > input { 
	width: 300px;
}
